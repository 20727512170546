import React from "react";
import "./AboutHeader.scss";
import { useStaticQuery, graphql } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { StaticImage, getImage } from "gatsby-plugin-image";
import { convertToBgImage } from "gbimage-bridge";

const AboutHeader = (props) => {
  const imageBack = useStaticQuery(graphql`
    {
      allFile(filter: { relativePath: { in: "solution-header-2400.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);
  const heroAbout = getImage(imageBack.allFile.edges[0].node);
  const heroAboutBG = convertToBgImage(heroAbout);
  return (
    <BackgroundImage
      Tags="section"
      {...heroAboutBG}
      alt="About Banner"
      className="ro herow hero-about"
    >
      <div className="ab">
        <div className="pad-top pad-bottom">
          <StaticImage
            loading="lazy"
            src={"../../../images/logo.png"}
            alt="About Sara Lee Premium Meats"
          />
          <h1 className="white">ABOUT SARA LEE PREMIUM MEATS</h1>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default AboutHeader;
