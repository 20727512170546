import React from "react";
import "./AboutNav.scss";

const AboutNav = ({ id }) => {

  const changePage = (newPage) => {
    window.location.assign(newPage);
  };

  const pageId =
    id === 2
      ? "/about/farm-to-table/"
      : id === 1
      ? "/about/helping-others/"
      : "/about/our-story/";
  return (
    <section>
      <div className="inner centered">
        <div className="about-nav">
          <a
            href="/about/our-story"
            className={id === 0 ? "current" : undefined}
          >
            Our Story
          </a>
          <a
            href="/about/helping-others"
            className={id === 1 ? "current" : undefined}
          >
            Helping Others
          </a>
          <a
            href="/about/farm-to-table"
            className={id === 2 ? "current" : undefined}
          >
            Farm to Table
          </a>
        </div>
        <select
          id="about-nav"
          className="filter-mobile"
          onChange={(event) => changePage(event.target.value)}
          value={pageId}
        >
          <option value="/about/our-story/">Our Story</option>
          <option value="/about/helping-others/">Helping Others</option>
          <option value="/about/farm-to-table/">Farm to Table</option>
        </select>
      </div>
    </section>
  );
};

export default AboutNav;
