import React from "react";
import Layout from "../components/Layout";
import AboutHeader from "../components/About/AboutHeader/AboutHeader";
import AboutNav from "../components/About/AboutNav/AboutNav";
import StoryBody from "../components/About/OurStory/Story";
import HelpingOthers from "../components/About/HelpingOthers/HelpingOthers";
import FarmToTable from "../components/About/FarmToTable/FarmToTable";

const About = (props) => {
  const { pageContext } = props;
  const i = pageContext?.id ?? 0;
  let pageTitle;
  {i === 0 ? (
    pageTitle = "Our Story"
  ) : i === 1 ? (
    pageTitle = "Helping Others"
  ) : (
    pageTitle = "Farm to Table"
  )}
  return (
    <Layout title={pageTitle} description="">
      <main>
        <article>
          <AboutHeader />
          <AboutNav id={i} />
          {i === 0 ? (
            <StoryBody />
          ) : i === 1 ? (
            <HelpingOthers />
          ) : (
            <FarmToTable />
          )}
        </article>
      </main>
    </Layout>
  );
};

export default About;
