import React from "react";
import OurStory from "../../../components/OurStory/OurStory";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
const HelpingOthers = (props) => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { eq: "food-matters.png" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);
  const foodMatters = getImage(data.allFile.edges[0].node);
  return (
    <OurStory
      image={foodMatters}
      bgColor="#d9e2e7"
      body={
        <div class="inner about-content">
          <h2>FOR THE LOVE OF FOOD</h2>
          <p>
            We at Sara lee and our parent company Tyson Foods know that food is
            more than sustenance – it is the basic foundation of health, growth,
            and security for kids everywhere. We have always believed that
            everyone should have access to wholesome, nutritious food.
          </p>
          <p>
            Sadly, one in six kids in the US struggles with food insecurity.
            This is why Sara Lee is dedicated to supporting our local food banks
            and other organizations that provide access to good food for those
            in need.
          </p>
          <p>
            Sara Lee is particularly proud to partner with Blessings in a
            Backpack, an organization that works to help provide weekend meals
            to school-aged kids across America who might otherwise go hungry. In
            2021, we helped to provide more than 48,000 meals to families in the
            Chicago area, home to our company’s headquarters. Inspired by the
            impact of our 2021 partnership this year we increased our support of
            Blessings in a Backpack to help provide 75,000 meals to kids facing
            food insecurity. And we’re just getting started.
          </p>
          <br />
        </div>
      }
    />
  );
};

export default HelpingOthers;
