import React from "react";
import OurStory from "../../../components/OurStory/OurStory";
import "./Story.scss";
import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
const Story = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          relativePath: {
            in: [
              "our-story-scaled.jpg"
              "ham-and-sam.jpg"
              "HAM-5-ways-group-shot-08.jpg"
            ]
          }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);
  const ourstory = getImage(data.allFile.edges[2].node);
  const hamAndSam = getImage(data.allFile.edges[0].node);
  const hamGroup = getImage(data.allFile.edges[1].node);
  return (
    <>
      <OurStory
        image={ourstory}
        bgColor="#fdc17c"
        altText="Our Story Image"
        body={
          <div class="inner about-content">
            <h2>
              THE SARA LEE BRAND…
              <br />
              ROOTED IN FAMILY
            </h2>
            <p>
              Sara Lee has long been a brand you know and trust. It all began
              when Charles Lubin bought a small chain of neighborhood bakeries
              in 1948 and, inspired by his own family, renamed the business “The
              Kitchens of Sara Lee” after his then 8-year-old daughter.
            </p>
            <p>
              Lubin took great pride in the quality and taste of everything he
              made. As Sara Lee tells it, her father said, “it had to be perfect
              because he was naming it after me.”
            </p>
            <p>&nbsp;</p>
            <br />
          </div>
        }
      />
      <OurStory
        image={hamAndSam}
        bgColor="#ffffff"
        reverse={true}
        altText="Ham and Sam"
        body={
          <div class="inner about-content">
            <h2>A TRADITION OF THE HIGHEST QUALITY, BEST TASTING PRODUCTS</h2>
            <p>
              Charles’ thriving business, built on the promise of delivering
              only the best tasting, highest quality products, soon attracted
              the attention of Consolidated Foods which acquired the Sara
              business in 1956. Sara Lee grew its distribution, introduced the
              iconic jingle (“Everybody doesn’t like something, but nobody
              doesn’t like Sara Lee!”), and gained fans nationwide. In 1985, to
              capitalize on the brand’s soaring popularity, Consolidated Foods
              changed its name to The Sara Lee Corporation.
            </p>
            <p>
              In early 2000’s, the Sara Lee brand expanded beyond its bakery
              heritage introducing Sara Lee Premium Deli meats merging with
              another beloved deli staple, Hillshire Brands in 2011. Today, Sara
              Lee Premium Meats, and the entire family of Hillshire Farms
              products, are proud to be a part of the Tyson Foods family, and we
              continue to honor Charles Lubin’s legacy by delivering the best
              tasting, highest quality premium meats.
            </p>
            <p>&nbsp;</p>
            <p>&nbsp;</p>
            <br />
          </div>
        }
      />
      <OurStory
        image={hamGroup}
        bgColor="#f1f1ee"
        altText="Ham 5 Ways Group Shot"
        body={
          <div class="inner about-content">
            <h2>
              SARA LEE PREMIUM DELI MEATS…
              <br />
              HONEST, AUTHENTIC AND REAL
            </h2>
            <p>
              Over time, our line of Sara Lee Premium Meats has grown to include
              more than 15 delicious varieties. From family favorites like Oven
              Roasted Turkey and Honey Ham to bolder creations like Mesquite
              Smoked Turkey and juicy Chipotle Seasoned Chicken Breast – we have
              something that’ll please everyone, from your pickiest eater to
              your favorite foodie.
            </p>
            <p>
              We’re proud to honor Charles’ Lubin’s legacy of unparalleled
              standards by crafting every one of our products the Sara Lee way –
              using only the finest cuts of meat, masterfully seasoned and slow
              roasted to perfection. We offer something for every diet including
              gluten-free and lower sodium options, and products crafted to meet
              the most exacting standards.
            </p>
            <p>
              Any way you slice it, Sara Lee Premium Deli meats are delicious
              products you can feel good about serving.
            </p>
            <br />
          </div>
        }
      />
    </>
  );
};

export default Story;
