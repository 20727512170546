import React from "react";
import OurStory from "../../../components/OurStory/OurStory";
import "./FarmToTable.scss";
import { getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";

const FarmToTable = (props) => {
  const query = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { eq: "tyson-food-image.jpg" } }) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                blurredOptions: { toFormat: WEBP }
                formats: WEBP
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
                webpOptions: { quality: 100 }
              )
            }
          }
        }
      }
    }
  `);

  const family = getImage(query.allFile.edges[0].node);
  return (
    <OurStory
      image={family}
      bgColor="#eddac1"
      imgClass="img-size"
      reverse={true}
      body={
        <div class="inner about-content">
          <h2>GETTING FROM FARM TO TABLE</h2>
          <p>
            We understand people want to know where their food comes from, which
            is why we’re committed to transparency in all we do. We believe it’s
            our responsibility to steward the land, animals, and resources
            entrusted to us and ensure we deliver the highest quality food from
            the farm to your table.
          </p>
          <h2>QUALITY FOOD STARTS ON THE FARM</h2>
          <p>
            Our goal is to make great food that is safe and satisfying. But,
            it’s important that we do so responsibly. Our service technicians,
            veterinarians, and animal welfare specialists routinely visit
            farmers to ensure animals are cared for properly.
          </p>
          <h2>GREAT FOOD COMES FROM PEOPLE WHO CARE</h2>
          <p>
            Great food starts with good people. Our relationships with our
            contract farmers are the cornerstone of our business. We’re proud to
            partner with over 11,000 independent farmers, some who have been
            successfully working with us for decades, and in some cases, for
            generations. We rely on them and work closely with them to provide
            healthy chicken, cattle and hogs that have been treated properly and
            raised with modern, proven animal care practices.
          </p>
          <br />
        </div>
      }
    />
  );
};

export default FarmToTable;
